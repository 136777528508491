


































import {Vue, Component, Prop} from 'vue-property-decorator'
import {BCard, BCardBody, BCardSubTitle, BCardTitle, BFormCheckbox, BTable, BButton, BAlert} from "bootstrap-vue";
import User = EmployeeAPI.User.User;

@Component({
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BButton, BAlert
  }
})
export default class UserPermissionsCard extends Vue {
  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  item?: User;

  items: EmployeeAPI.CrmAccess.CrmAccess[] = []

  async mounted() {
    await this.getItems();
  }

  async getItems(refetch = false) {
    const crmaccess = await this.$api.employee.crmaccess.GetList(this.$route.params.identifier, refetch);
    this.$set(this, "items", crmaccess);
  }

  async refetch() {
    this.$set(this, "items", []);
    await this.getItems(true)
  }
}

