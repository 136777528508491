





























import { Vue, Component, Prop } from 'vue-property-decorator'
import {BCard, BCardBody, BCardSubTitle, BCardTitle, BFormCheckbox, BTable} from "bootstrap-vue";
import User = EmployeeAPI.User.User;

@Component({
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox
  }
})
export default class UserPermissionsCard extends Vue {
  @Prop({default: () => {return {}}, required: true})
  item?: User;

  permissionsData = [
    {
      module: 'Admin',
      read: true,
      write: true,
      create: true,
      delete: true,
    },
  ]
}

