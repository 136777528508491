


















import {Vue, Component, Prop} from 'vue-property-decorator'
import {BAvatar, BCard, BImg, BMedia} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import User = EmployeeAPI.User.User;
import {startCase} from "lodash";

@Component({
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
  filters: {
    startCase
  },
})
export default class UserActivityCard extends Vue {
  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  item?: User;

  items: EmployeeAPI.ActivityLog.ActivityLog[] = [];

  async mounted() {
    await this.getItems();
  }

  async getItems() {
    const userId = this.$route.params["identifier"];
    const items = await this.$api.employee.activitylog.GetList(userId, 1, 5);
    this.$set(this, "items", items.items);
  }
}
