






































import {Vue, Component, Prop} from 'vue-property-decorator'
import User = EmployeeAPI.User.User;
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import UserInfoCard from "@/apps/employee-api/pages/Users/single/components/user-info-card.vue";
import UserPermissionsCard from "@/apps/employee-api/pages/Users/single/components/user-permissions-card.vue";
import UserActivityCard from "@/apps/employee-api/pages/Users/single/components/user-activity-card.vue";
import UserCrmaccessCard from "@/apps/employee-api/pages/Users/single/components/user-crmaccess-card.vue";

@Component({
  components: {UserCrmaccessCard, UserActivityCard, UserPermissionsCard, UserInfoCard, BRow, BCol, BAlert, BLink}
})
export default class EmployeeUser extends Vue {
  item?: User = null;

  get id() {
    return this.$route.params.identifier;
  }

  async mounted() {
    await this.getItem();
  }

  async getItem() {
    const user = await this.$api.employee.user.Get(this.id);
    this.$set(this, "item", user);
  }
}
