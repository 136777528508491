



















































































































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {BAvatar, BButton, BCard, BCol, BRow, BModal, BForm, BFormGroup, BFormInput} from "bootstrap-vue";
import User = EmployeeAPI.User.User;
import {avatarText} from '@/helpers/filters/avatarText';
import {VForm} from "@/global";
import {NotifyError, NotifySuccess} from "@/helpers/Toast";

@Component({
  components: {BCard, BButton, BRow, BCol, BAvatar, BModal, BForm, BFormGroup, BFormInput}
})
export default class UserInfoCard extends Vue {
  $refs!: {
    form: BForm,
    formValidation: VForm
  }

  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  item?: User;

  get fullName() {
    return `${this.item.firstName} ${this.item.lastName}`
  }

  avatarText() {
    return avatarText(this.fullName)
  }

  passwordChangeModel = {
    show: false,
    password: null,
    passwordConfirm: null
  };

  resetPasswordChange() {
    this.$refs.formValidation?.reset();

    this.passwordChangeModel.password = null;
    this.passwordChangeModel.passwordConfirm = null;
  }

  async confirmPasswordChange(bvModalEvt) {
    bvModalEvt.preventDefault();

    if (!await this.$refs.formValidation.validate()) return;

    const changeSuccess = await this.$api.employee.user.ChangePassword(this.item.id, this.passwordChangeModel.password, this.passwordChangeModel.passwordConfirm);
    if (changeSuccess) {
      NotifySuccess([
        `${this.item.firstName} ${this.item.lastName} (${this.item.email}) Password Changed Successfully`,
      ]);
      this.resetPasswordChange();
      this.passwordChangeModel.show = false;
    } else {
      NotifyError([
        `User Password Change Failed`,
      ]);
    }
  }
}
